import React from 'react';
import { Edit, useRecordContext, useNotify } from 'react-admin';  
  
import { makeStyles, useTheme } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';  
 
import DataStorePage from './DataStorePage';
import MeterForm from './MeterForm'; 
import TabPanel from './TabPanel';
const useStyles = makeStyles((theme) => {
    return ({
        raEdit: {
            card: {
                backgroundColor: "#F5F7FA"
            },
            root: {
                marginTop: "0px"
            },
        },
        appBar: {
            // width: "100px"
        },
        tabPanel: {
          backgroundColor: theme.palette.background.paper,
          border: '2px solid #DEDEDE', 
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          // padding:"5px"
        },
        root: {
          paddingTop: "10px"
        }
    })
});

const MeterEdit = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const Title = () => {
      const record = useRecordContext();
      return <span>Измерители/Серийный номер: {record ? `${record.serial_number}` : ''}</span>;
    };

    const a11yProps = (index) => {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
    }
    const notify = useNotify();

    const onError = (error) => {
        if(error?.body){
          notify(`Возникла ошибка при изменении: ${Object.values(error.body).toString()}`, { type: 'error' });
        }
        else{
          notify(`Возникла ошибка при изменении: ${error?.message}`, { type: 'error' });
        }
    };

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default" className={classes.AppBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary" 
            aria-label="full width tabs example"
          >
            <Tab label="Потребление/Графики" {...a11yProps(0)} /> 
            <Tab label="Настройка" {...a11yProps(1)} />
          </Tabs>
        </AppBar> 
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <DataStorePage/> 
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.tabPanel}>
            <Edit 
              title={<Title />} {...props} 
              fullWidth 
              actions={null} 
              sx={{
                paddingBottom: "100px", 
                "& .RaEdit-main":{marginTop: "0px", padding: "0px"}}}
              mutationOptions={{onError}} >
                <MeterForm/>
            </Edit>
        </TabPanel> 
      </div>
    );
}

export default MeterEdit;