import React from 'react';
import { Create, useNotify } from 'react-admin';  
import { makeStyles } from '@mui/styles'; 
import MeterForm from './MeterForm';

const useStyles = makeStyles((theme) => {
    return ({ 
        raCreate: {
            card: {
                backgroundColor: "#F5F7FA"
            
            },
            noActions: {
                marginTop: "0px"
            },
        }
    })
});


const MeterCreate = props => {
    const classes = useStyles();
    const notify = useNotify();

    const onError = (error) => {
        if(error?.body){
            notify(`Возникла ошибка при добавлении: ${Object.values(error.body).toString()}`, { type: 'error' });
        }
        else{
            notify(`Возникла ошибка при добавлении: ${error?.message}`, { type: 'error' });
        }
    };
    return(
        <Create 
            title={<span>Измерители/Добавить</span>} {...props} 
            classes={classes.raCreate} 
            sx={{marginBottom: "100px"}}
            mutationOptions={{onError}}
            >
            <MeterForm />
        </Create>
    )
};

export default MeterCreate;